<script setup lang="ts">
import { InviteMemberDocument } from './InviteModal.generated'

const open = defineModel<boolean>({ required: true })
const inviteForm = useTemplateRef('inviteForm')

function close() {
  open.value = false
}

const { execute: invite, isFetching: isInviting } = useMutation(InviteMemberDocument, { refetchTags: ['invites', 'flashMessages', 'users'] })

async function onSubmit(emails: string[]) {
  const responses = await Promise.all(emails.map(email => invite({ input: { email, role: 'member' } })))

  responses.forEach((response, index) => {
    if (response.data?.inviteMember?.errors && response.data.inviteMember.errors.length > 0) {
      inviteForm.value?.form.setErrors(
        Object.entries(response.data?.inviteMember?.errors).map(([_key, value]) => ({ path: `email[${index}]`, message: value?.messages.join(' ') })),
      )
    }
  })

  if (responses.every(response => response.data?.inviteMember?.success ?? false))
    close()
}
</script>

<template>
  <UModal v-model="open" fullscreen :ui="{ fullscreen: 'flex dark:!bg-gray-950/85 !bg-gray-950/70 items-center justify-center' }" @close="close">
    <div class="w-[40rem] max-w-full rounded-2xl border border-gray-300 bg-white p-4 dark:border-gray-700 dark:bg-gray-900">
      <div class="flex items-start justify-between">
        <div>
          <h3>Invite members to your organization</h3>
          <div class="text-base text-gray-700 dark:text-gray-200">
            Invite new people to join your organization.
          </div>
        </div>
        <UButton icon="light:xmark" variant="ghost" size="xl" color="gray" @click="close" />
      </div>

      <SettingsOrgInviteForm
        ref="inviteForm"
        submit-btn-text="Invite to Organization"
        :loading="isInviting"
        @submit="onSubmit"
      />
    </div>
  </UModal>
</template>
