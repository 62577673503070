<script setup lang="ts">
defineProps<{
  loading?: boolean
  submitBtnText: string
}>()

defineEmits<{
  submit: [emails: string[]]
}>()

const form = ref()
defineExpose({ form })
const container = ref<HTMLElement>()

const { height } = useElementSize(container)
const { y: containerYScroll } = useScroll(container, { behavior: 'smooth' })

const initialEmails = ['', '', '']
const state = reactive({ emails: [...initialEmails] })

onUnmounted(() => {
  state.emails = [...initialEmails]
})

function addMore() {
  state.emails = [...state.emails, ...initialEmails]
  nextTick(() => containerYScroll.value = height.value)
}

function handlePaste(ev: ClipboardEvent) {
  if (!ev.clipboardData)
    return

  const pasted = ev.clipboardData.getData('text')
  const splitCharacter = ['\n', ',', ';', ' ', '	'].find(c => pasted.includes(c)) ?? '\n' // eslint-disable-line style/no-tabs

  const emails = pasted.split(splitCharacter).filter(Boolean)
  if (emails.length < 2)
    return

  ev.preventDefault()
  state.emails = [
    ...state.emails.filter(Boolean), // keep existing filled emails
    ...emails.map(e => e.trim()),
  ]
}
</script>

<template>
  <UForm ref="form" class="mt-4 flex flex-col" :state="state" :validate-on="['submit']" @submit="() => $emit('submit', state.emails.filter(Boolean))">
    <div ref="container" class="max-h-96 overflow-auto p-0.5">
      <FormGroup
        v-for="(_email, index) in state.emails"
        :key="index"
        :name="`email[${index}]`"
      >
        <UInput
          v-model="state.emails[index]"
          class="mt-2"
          placeholder="name@example.com"
          icon="light:envelope"
          type="email"
          :disabled="loading || state.emails[index - 1]?.length === 0"
          :autofocus="index === 0"
          @paste="handlePaste"
        />
      </FormGroup>
    </div>
    <div class="my-4 flex items-center gap-x-1 text-xs text-gray-300">
      <UTooltip text="Can be split by newline, whitespace or comma">
        <UIcon name="light:circle-info" />
      </UTooltip>
      <span>You can paste multiple emails at once</span>
    </div>
    <div class="flex justify-between">
      <UButton color="gray" icon="light:plus" :disabled="state.emails.some(e => e.length === 0)" @click="addMore">
        Add more
      </UButton>
      <UButton type="submit" data-action="invite-to-org-button" :loading="loading">
        {{ submitBtnText }}
      </UButton>
    </div>
  </UForm>
</template>
